import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { STORAGEKEYS } from "../common/keys";
import { ILenConfigState, ILoanCalculatorState } from "./types";
import { createBrowserHistory } from 'history';
import { SessionStorage } from "../common/cookie-storage";
import { lenConfigReducer, lenConfigInitialState } from './reducers/len-config-reducer'
import { loanCalculatorInitialState, loanCalculatorReducer } from './reducers/loan-calculator-reducer'

export type AppState = {
  lenConfigState: ILenConfigState,
  loanCalculatorState: ILoanCalculatorState,
}
const rootReducer = () => combineReducers({
  lenConfigState: lenConfigReducer,
  loanCalculatorState: loanCalculatorReducer,
});

export function configureStore() {
  const state = loadState();
  const store = createStore(rootReducer(),state, applyMiddleware(thunk));
  store.subscribe(()=>{
    saveCurrentState(store.getState());
  })
  return store;
}

const saveCurrentState = (state : AppState)=>{
  const sessionStorage = new SessionStorage();

  const lenConfigJSON = JSON.stringify(state.lenConfigState)
  sessionStorage.add(STORAGEKEYS.LEN_CONFIG_STATE, lenConfigJSON)

  const loanCalculatorJSON = JSON.stringify(state.loanCalculatorState)
  sessionStorage.add(STORAGEKEYS.LOAN_CALCULATOR_STATE, loanCalculatorJSON)
}

function getSessionState<T>(key: string, initialState : T){
  const sessionStorage = new SessionStorage();
  const stateJson = sessionStorage.get(key);
  let state = initialState;
  if(stateJson != null && stateJson){
    state = JSON.parse(stateJson);
  }
  return state;
}

const loadState = () => {
  const lenConfigState = getSessionState(STORAGEKEYS.LEN_CONFIG_STATE, lenConfigInitialState)
  const loanCalculatorState = getSessionState(STORAGEKEYS.LOAN_CALCULATOR_STATE, loanCalculatorInitialState)

  return {
    lenConfigState: lenConfigState,
    loanCalculatorState: loanCalculatorState,
  }
}
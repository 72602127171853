import { AppLinks } from './app-links'

export class Environment{
    public static apiUrl = process.env.NODE_ENV === 'development' ?
      "https://localhost:9447/Api/" :
      process.env.REACT_APP_BACKEND_URL as string;

    public static baseUrl = process.env.NODE_ENV === 'development' ?
      "https://localhost:3001/" :
      process.env.REACT_APP_FRONTEND_URL as string;

    public static startOpenaProcessUrl = Environment.baseUrl + AppLinks.loanForm.replace("/", "");
    public static startOpenaProcessWithAffiliateDataUrl = Environment.baseUrl + AppLinks.affiliate.replace("/", "");

    public static phoneNumberPrefix = "+48 ";

    public static defaultAffiliateCookieExpiresTimeInHours = 720;

}
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


/**
 * Komponent wymuszający scroll do początku danej strony, podczas zmiany adresu w przeglądarce.
 * @returns 
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
export const AppLinks = {
   default: '/',
   home: '/',
   howItWorks: process.env.NODE_ENV === 'development' ? 'jak-to-dziala' : process.env.REACT_APP_REPRESENTATIVE_PAGE_HOW_IT_WORKS_URL_PATH ?? '',
   faq: process.env.NODE_ENV === 'development' ? 'pytania-i-odpowiedzi' : process.env.REACT_APP_REPRESENTATIVE_PAGE_FAQ_URL_PATH ?? '',
   docsToDownload: process.env.NODE_ENV === 'development' ? 'dokumenty' : process.env.REACT_APP_REPRESENTATIVE_PAGE_DOC_TO_DOWNLOAD_URL_PATH ?? '',
   contact: process.env.NODE_ENV === 'development' ? 'kontakt' : process.env.REACT_APP_REPRESENTATIVE_PAGE_CONTACT_URL_PATH ?? '',

   loanForm:  process.env.NODE_ENV === 'development' ? '/formularz/zloz-wniosek' : process.env.REACT_APP_FORM_STARTING_URL_PATH ?? '',
   affiliate: process.env.NODE_ENV === 'development' ? '/affiliate' : process.env.REACT_APP_AFFILIATE_URL_PATH ?? '',
}
import React from 'react';
import { Outlet } from 'react-router-dom'
import Footer from '../Footer/PageFooter'
import Navbar from '../Navbar/Navbar'

import './PageLayoutStyles.scss'

const PageLayout = () => {

   return (
      <div className='page-layout-outer-wrapper'>
         <Navbar />
         <div className='page-content-wrapper'>
            <Outlet />
         </div>
         <Footer />
      </div>
   )
}

export default PageLayout
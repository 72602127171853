import { HttpClient } from "../common/http-client";
import { Environment } from "../environment/app-settings";

export interface ICalculateLoanParams { 
   instalments: number,
   requestedAmount: number,
}

export interface ILoanInitLimits {
  minInstalments: number;
  maxInstalments: number;
  minRequestedAmount: number;
  maxRequestedAmount: number;
  stepRequestedAmount: number;
}

export interface ILoanInitCalculate{
  loanAmountGross: number;
  loanAmountNet: number;
  instalments: number;
  ckdz: number;
  rrso: number;
  interestRate: number;
  instalmentAmount: number;
  minimalCommisionAmount: number;
  mininalAmountToPay: number;
  earlyRRSO: number;
  paymentDate: number;
}

export interface ILoanInitCreateApplicationParams {
  userEmail: string;
  amountToPay: number;
  instalmentNumber: number;
}

export interface ILoanInitCreateApplicationResponse {
  redirectUrl: string;
}

export interface IAffiliateStatisticRecordParams {
  partnerId: string;
  clientId: string | null;
  affiliateData: string;
  source: typeof LoanInitService.AFFILIATE_STATISTIC_REPRESENTATIVE_PAGE_VALUE;
}

export interface AffiliateStatisticRecordResponse {}

export class LoanInitService {
  private readonly _client: HttpClient;
  private readonly _basedUrl = "loanInit";
  private readonly _limitsUrlPath = '/limits';
  private readonly _calculateUrlPath = '/calculate';
  private readonly _createApplicationUrlPath = '/createApp';
  private readonly _addAffiliateStatisticRecordUrlPath = '/addAffiliateStatistic'
  public static AFFILIATE_STATISTIC_REPRESENTATIVE_PAGE_VALUE: 'Development_InfoPage' | 'InfoPage' = process.env.NODE_ENV === 'development'?  'Development_InfoPage' : 'InfoPage'; //Wartość stała dla Formularza. 

  public readonly defaultInstalmentsNumber = 44;
  public readonly defaultRequestedAmount = 10000;

  constructor() {
    this._client = new HttpClient(Environment.apiUrl + this._basedUrl);
  }

  public async calculateLoanOffert(loanCalculateParams: ICalculateLoanParams): Promise<ILoanInitCalculate> {
    return (await (this._client.post<ILoanInitCalculate, ICalculateLoanParams>(this._calculateUrlPath, loanCalculateParams))).data; 
  }

  public async getLoanLimits(): Promise<ILoanInitLimits> {
    return (await (this._client.post<ILoanInitLimits, {}>(this._limitsUrlPath, {}))).data;
  }

  public async createApplication(createApplicationParams: ILoanInitCreateApplicationParams): Promise<ILoanInitCreateApplicationResponse> {
    return (await (this._client.post<ILoanInitCreateApplicationResponse, ILoanInitCreateApplicationParams>(this._createApplicationUrlPath, createApplicationParams))).data;
  }

  public async addAffiliateStatisticRecord(affiliateStatisticRecordParams: IAffiliateStatisticRecordParams): Promise<void> {
    await (this._client.post<AffiliateStatisticRecordResponse, IAffiliateStatisticRecordParams>(this._addAffiliateStatisticRecordUrlPath, affiliateStatisticRecordParams))
  }

}

import Cookies from "universal-cookie/es6";

export class SessionStorage{
    public add(key: string, value: string){
        sessionStorage.setItem(key,value);
    }

    public get(key : string) : string {
        return sessionStorage.getItem(key) as string ;
    }

    public addCookie(key : string, value : string){
        const cookie = new Cookies();
        cookie.set(key, value, {secure: true})
    }
}
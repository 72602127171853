import axios, { AxiosInstance, AxiosResponse } from "axios";

export class HttpClient {
  private readonly _instance: AxiosInstance;

  public constructor(baseURL: string) {
    this._instance = axios.create({
      baseURL,
    });
    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this._instance.interceptors.response.use(
      this._handleResponse,
    );
  };

  private _handleResponse = (data : AxiosResponse) => data;

  public get<T>(url: string, timeout: number = 10000): Promise<AxiosResponse<T>> {
    return this._instance.get(url, {
      headers: { "content-type": "application/json" },
      timeout: timeout,
      withCredentials : true,
    });
  }

  public post<T, Body>(url: string, body: Body,): Promise<AxiosResponse<T>> {
    return this._instance.post(url, body, {
      headers: { "content-type": "application/json" },
    });
  }

  public download (url: string) : Promise<AxiosResponse<Blob>> {
    return this._instance.get(url, {
      headers: { "content-type": "application/json" },
      responseType: 'blob'
    });
  }
}

import { Environment } from '../../environment/app-settings'

/**
 * Funkcja dodająca odpowiednią odmianę słowa "miesiąc" w zależności od ilości miesięcy ("miesiąca", "miesięcy", "miesiące")
 * Funkcja bazuje na artykule: https://sjp.pwn.pl/poradnia/haslo/Formy-rzeczownika-miesiac-po-liczebnikach;20948.html
 * @param {Number} monthsAmount - liczba reprezentująca ilość miesięcy  
 * @returns {String}
 */
export const monthsAmountDisplayedNames = (monthsAmount: number): string => {
   
   const lastDigit = Number(String(monthsAmount).slice(-1))
   const numeralNames = {
      OPTION_A: 'miesiąc',
      OPTION_B: 'miesiące',
      OPTION_C: 'miesięcy'
   }

   if(monthsAmount === 1){ //Opcja a) - jeśeli ilość miesięcy = 1 
      return `${monthsAmount} ${numeralNames.OPTION_A}`
   }
   else if([2, 3, 4].includes(monthsAmount)){ //Opcja b) - jeżeli ilość miesięcy = 2 lub 3 lub 4
      return `${monthsAmount} ${numeralNames.OPTION_B}` 
   }
   else if(monthsAmount >= 5 && monthsAmount <= 21){ //Opcja c) - jeśli ilość miesięcy jest z zakresu od 5 do 21
      return `${monthsAmount} ${numeralNames.OPTION_C}`
   }
   else if([2, 3, 4].includes(lastDigit)){ //Opcja b) - jeśli ostatnia cyfra ilości miesięcy to 2 lub 3 lub 4 
      return `${monthsAmount} ${numeralNames.OPTION_B}`
   }
   else if([0, 1, 5, 6, 7, 8, 9].includes(lastDigit)){ //Opcja c) - jeśli ostatnia cyfra ilości miesięcy to 0, 1, 5, 6, 7, 8 lub 9 
      return `${monthsAmount} ${numeralNames.OPTION_C}`
   }

   else return `${monthsAmount}` //Żadne z powyższych warunków
}

/**
 * Funkcja która po podaniu numeru telefonu zwraca przygotowany string do wstawienia w atrybut `href` znacznika `<a />`.
 * Dzięki dodaniu 'tel:' po kliknięciu w taki odnośnik, na urządzeniach mobilnych zostanie otwarta aplikacja do wykonywania połączeń. 
 * @param phoneNumber - Numer telefonu
 * @returns 
 */
export const preparePhoneNumberToHref = (phoneNumber: string | undefined) => {
   if(phoneNumber?.length){
      let phoneNumberTemp = ''
      const phonePrefix = Environment.phoneNumberPrefix.trim()
      
      if(phoneNumber.startsWith(phonePrefix)){
         phoneNumberTemp = phoneNumber.replaceAll(/\s/g, '')
      }
      else {
         phoneNumberTemp = `${phonePrefix}${phoneNumber}`.replaceAll(/\s/g, '')
      }

      return `tel:${phoneNumberTemp}`.replaceAll(/\s/g, '')
   }

   else return ''
}
import { HttpClient } from "../common/http-client";
import { Environment } from "../environment/app-settings";
import { ILenConfigState } from '../store/types'

//#region SettingService Instance
let instance: LenConfigService
//#endregion

//#region Interfaces
export interface ILenConfigFetchModel {
  data: ILenConfigState;
}
//#endregion

export class LenConfigService{
    private readonly _client: HttpClient;
    private readonly _settingsUrlPath = "settings";
    private readonly _lenConfigUrlPath = "/globalConfig";
  
    constructor() {
        if (instance) { //SINGLETON DESIGN PATTERN
           throw new Error("New instance cannot be created!!");
        }
     
        instance = this;
        this._client = new HttpClient(Environment.apiUrl);
    }

    public async getLenConfig() : Promise<ILenConfigFetchModel>{
      const url = `${this._settingsUrlPath}${this._lenConfigUrlPath}`;
      return (await this._client.get<ILenConfigFetchModel>(url)).data;
    }
}

let lenConfigService = Object.freeze(new LenConfigService())//SINGLETON DESIGN PATTERN
export default lenConfigService;
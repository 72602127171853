/**
 * Komponent stworzony na podstawie artykułu: https://dev.to/omogbai/code-splitting-with-react-router-v6-react-lazy-and-suspense-in-simple-terms-5365
 */
import React, { Suspense } from "react";

interface SuspenseWrapperProps {
   path: string;
 }

const SuspensePagePlaceholder = () => {
  return (
    <div style={{ height: '100vh' }} />
  )
}
 
const SuspenseWrapper = (props: SuspenseWrapperProps) => {
  const LazyComponent = React.lazy(() => import(`./pages/${props.path}`));

  return (
    <Suspense fallback={<SuspensePagePlaceholder />}>
      <LazyComponent />
    </Suspense>
  );
};

export default SuspenseWrapper;
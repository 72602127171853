import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom'

import { AppLinks } from '../../environment/app-links'
import PageLayout from '../../components/PageLayout/PageLayout'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import SuspenseWrapper from '../../SuspenseWrapper'
import useAppRootLogic from './hooks/useAppRootLogic'

import './AppRoot.css';

const AppRoot = () => {

  useAppRootLogic()
  const documentsLinksConfig = useSelector((state: AppState) => state.lenConfigState.documentsLinksConfig)
 
  return (
    <Routes>
        <Route path={AppLinks.default} element={<PageLayout />} >
          <Route index element={<SuspenseWrapper path="HomePage/HomePage"/> }/>
          <Route path={AppLinks.howItWorks} element={<SuspenseWrapper path="HowItWorks/HowItWorksPage"/>}/>
          <Route path={AppLinks.faq} element={<SuspenseWrapper path="FaqPage/FaqPage"/>}/>
          <Route path={AppLinks.docsToDownload} element={<SuspenseWrapper path="DocsToDownloadPage/DocsToDownloadPage"/>}/>
          <Route path={AppLinks.contact} element={<SuspenseWrapper path="ContactPage/ContactPage"/>}/>
          <Route path={AppLinks.affiliate} element={<SuspenseWrapper path="AffiliatePage/AffiliatePage"/>}/>
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path={documentsLinksConfig.privacyPolicy.displayedLink} element={<Navigate to={documentsLinksConfig.privacyPolicy.path} replace />}/>
          <Route path={documentsLinksConfig.websiteRegulations.displayedLink} element={<Navigate to={documentsLinksConfig.websiteRegulations.path} replace />}/>
        </Route>
    </Routes>
  )
}

export default AppRoot;
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { ICalculateLoanParams, LoanInitService } from '../../../service/LoanInitService'
import lenConfigService from '../../../service/LenConfigService'
import { SET_LOAN_CALCULATE_PARAMS, SET_LOAN_LIMITS } from '../../../store/actions/loan-calculator-actions'
import { SET_LEN_CONFIG } from '../../../store/actions/len-config-actions'


/**
  * Custom hook obsługujący logikę strony bazowej - AppRoot
*/
const useAppRootLogic = () => {

   const dispatch = useDispatch()
   const loanInitService = new LoanInitService()
 
   useEffect(() => {
     (async () => {
       const loanLimitsParams = await loanInitService.getLoanLimits()
 
       // const calculateLoanParams: ICalculateLoanParams = { //Przeliczenie dla maksymalnych możliwych parametrów pożyczki - Przywrócić w razie potrzeby
       //   instalments: loanLimitsParams.maxInstalments,
       //   requestedAmount: loanLimitsParams.maxRequestedAmount
       // }
 
       const calculateLoanParams: ICalculateLoanParams = {
         instalments: loanInitService.defaultInstalmentsNumber,
         requestedAmount: loanInitService.defaultRequestedAmount
       }
 
       //Wstępne przeliczenie - używamy maksymalnych dostępnych parametrów pożyczki 
       const initialLoanOffert = await loanInitService.calculateLoanOffert(calculateLoanParams)
 
       //Pobieramy konfigurację aplikacji
       const lenConfig = await lenConfigService.getLenConfig()
 
       dispatch({
         type: SET_LOAN_LIMITS,
         payload: loanLimitsParams
       })
 
       dispatch({
         type: SET_LOAN_CALCULATE_PARAMS,
         payload: initialLoanOffert
       })
 
       dispatch({
         type: SET_LEN_CONFIG,
         payload: lenConfig.data
       })
 
     })()

   }, [])
}

export default useAppRootLogic

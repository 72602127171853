import React from "react";
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppState } from "../../store";
import OpenaLogoWhite from "../../asserts/logos/main-logo-white.svg";
import ForwardIcon from '../../asserts/icons/forward-icon.svg'
import PaytreeLogo from '../../asserts/logos/paytree-logo.svg'
import ZPFLogo from '../../asserts/logos/zpf-logo.svg';
import { PhoneNumberProps } from './FooterProps'
import { AppLinks } from '../../environment/app-links'
import { IDocumentsLinksConfig } from '../../store/types'
import { preparePhoneNumberToHref } from '../../common/helpers/helpers'

import "./FooterSyles.scss";

const FooterDocsLinks = (props: {documentsLinksConfig: IDocumentsLinksConfig}) => {

  const { documentsLinksConfig } = props

  const handleOnClick = (e: React.MouseEvent<HTMLAnchorElement>, link: string) => {
    e.preventDefault() //Blokujemy akcję kliknięcia w przycisk
    window.open(link, '_blank') //Otwieramy pełen link w nowej karcie
  }

  return (
    <>
      <Link to={AppLinks.howItWorks} className='how-it-works'>Jak to działa?</Link>
      <Link to={AppLinks.faq} className='faq'>Pytania i odpowiedzi</Link>
      <Link to={AppLinks.contact} className='contact'>Kontakt</Link>
      <Link onMouseDown={(e) => handleOnClick(e, documentsLinksConfig.privacyPolicy.path)} to={documentsLinksConfig.privacyPolicy.displayedLink} className='privacy-policy'>
        {documentsLinksConfig.privacyPolicy.text}
      </Link>
      <Link onMouseDown={(e) => handleOnClick(e, documentsLinksConfig.websiteRegulations.path)} to={documentsLinksConfig.websiteRegulations.displayedLink} className='website-regulations'>
        {documentsLinksConfig.websiteRegulations.text}
      </Link>
      <Link to={AppLinks.docsToDownload} className='docs-to-download'>Dokumenty do pobrania</Link>
    </> 
  )
}

const PhoneNumber = (props: PhoneNumberProps) => {
  if(!props.isPhoneAvailable){
    return null
  }

  return (
    <>
      <img src={ForwardIcon} alt="forward-icon" />
      <a href={preparePhoneNumberToHref(props.phoneNumber)}>
        {props.phoneNumber}
      </a>
    </>
  )
}

const Footer = () => {

  const lenConfigState = useSelector((state: AppState) => state.lenConfigState)
  const {footerConfig, documentsLinksConfig} = lenConfigState

  return (
    <footer className='page-footer-outer-wrapper' >
     <hr className='separator'/>
      <div className='footer'>
        <div className='footer-heading-logo'>
          <img src={OpenaLogoWhite} alt="Lentos logo white"/>
          <div className='footer-line-after-logo'/>
        </div>
        <div className='footer-text-wrapper'>
            <p className='footer-heading-1'><b>O firmie</b></p>
            <p className='footer-heading-2'><b>Dokumenty</b></p> 
            <FooterDocsLinks documentsLinksConfig={documentsLinksConfig} />
            <div className='gap'/>
            <p className='contact-heading'><b>Kontakt</b></p>
            <div className='footer-phone-wrapper'>
                <PhoneNumber isPhoneAvailable={footerConfig.isPhoneAvailable} phoneNumber={footerConfig.phoneNumber} />
            </div>
            <p className='opening-hours'>Dni robocze: 9:00 - 17:00</p>
            <p className='logo-heading-1'><b>Powered by</b></p>
            <p className='logo-heading-2'><b>Należymy do</b></p>
            <a href='https://paytree.pl' target="_blank" rel="noreferrer" className='logo-1'>
              <img  src={PaytreeLogo} alt="Paytree logo" />
            </a>
            <a href='https://zpf.pl' target="_blank" rel="noreferrer" className='logo-2'>
              <img src={ZPFLogo} alt="ZPF logo"/>
            </a>
            <div className='formal-text-gap'/>
            <p className='footer-formal-text-wrapper'>
                Rzeczywista Roczna Stopa Oprocentowania (RRSO) dla przykładu reprezentatywnego wynosi: 54,12%, całkowita kwota pożyczki 
                (bez kredytowanych kosztów) wynosi: 5 500,00 zł, oprocentowanie stałe w skali roku: 18,5%; czas obowiązywania umowy: 42 miesiące; 
                wysokość miesięcznych równych rat: 260,00 zł; oraz ostatnia rata wyrównująca w wysokości: 169,99 zł; całkowita kwota do zapłaty: 10 829,99 zł; 
                całkowity koszt pożyczki: 5 329,99 zł (w tym: odsetki 2 854,99 zł, prowizja 2 475,00 zł). Kalkulacja została dokonana na dzień 05-10-2023 r.
            </p>
        </div>
        <div className='footer-breakline'/>
        <div className="footer-copyright">
          <p >&#169; 2023 Paytree SA właściciel marki Opena</p>
        </div>
      </div>
    </footer>
  )
};

export default Footer

import { LoanCalculatorActions, TOGGLE_CALCULATOR_OPEN, SET_LOAN_LIMITS, SET_LOAN_CALCULATE_PARAMS } from "../actions/loan-calculator-actions";
import { ILoanCalculatorState } from "../types";

export const loanCalculatorInitialState: ILoanCalculatorState = {
  requestedAmount: 0,
  instalments: 0,
  minLoanAmount: 0,
  maxLoanAmount: 0,
  minLoanPeriod: 0,
  maxLoanPeriod: 0,
  loanAmountStep: 0,
  instalmentAmount: 0,
  ckdz: 0,
  interestRate: 0,
  rrso: 0,

  isOffertReady: false,
  isCalculatorOpen: true,
};

  export function loanCalculatorReducer(state: ILoanCalculatorState = loanCalculatorInitialState, action: LoanCalculatorActions) {
    switch (action.type) {
      case SET_LOAN_CALCULATE_PARAMS: {
      return {  
            ...state,
            requestedAmount: action.payload.loanAmountNet,
            instalments: action.payload.instalments,
            instalmentAmount: action.payload.instalmentAmount,
            ckdz: action.payload.ckdz,
            interestRate: action.payload.interestRate,
            rrso: action.payload.rrso,
        }
      }
      case TOGGLE_CALCULATOR_OPEN: {
         return {
            ...state,
            isCalculatorOpen: action.payload
         }
      }
      case SET_LOAN_LIMITS: {
        return {
          ...state,
          maxLoanAmount: action.payload.maxRequestedAmount,
          minLoanAmount: action.payload.minRequestedAmount,
          minLoanPeriod: action.payload.minInstalments,
          maxLoanPeriod: action.payload.maxInstalments,
          loanAmountStep: action.payload.stepRequestedAmount,

          isOffertReady: true,
        }
      }
      default: {
        return state;
      }
    }
  }
import { LenConfigActions, SET_LEN_CONFIG } from '../actions/len-config-actions'
import { ILenConfigState } from "../types";

export const lenConfigInitialState: ILenConfigState = {
  footerConfig: {
    phoneNumber: "",
    isPhoneAvailable: false,
    officeOpenTime: "",
    officeClosedTime: "",
  },
  globalConfig: {
    sendSmsBlockingTimeInMs: 0,
    sendSmsInitialBlockingTime: 0,
    sendSmsLimit:0,
    minimumLoanAmount: 0,
    defaultOffertSliderStep: 0,
    affiliateCookieExpiresTimeInHours: 1,
    workTimeFrom: { 
          hours: 0, 
          minutes: 0
    },
    workTimeTo: { 
          hours: 0, 
          minutes: 0
    },
  },
  documentsLinksConfig: {
    contractTemplate: {path: '', displayedLink: '', download: false, text: ''},
    cookiePolicy: {path: '', displayedLink: '', download: false, text: ''},
    informationClause: {path: '', displayedLink: '', download: false, text: ''},
    informationForm: {path: '', displayedLink: '', download: false, text: ''},
    privacyPolicy: {path: '', displayedLink: '', download: false, text: ''},
    websiteRegulations: {path: '', displayedLink: '', download: false, text: ''},
  }
};
  
export function lenConfigReducer(
    state: ILenConfigState  = lenConfigInitialState,
    action: LenConfigActions
) {
      switch(action.type){
         case SET_LEN_CONFIG:{
           return {
               ...state,
               footerConfig: action.payload.footerConfig,
               globalConfig: action.payload.globalConfig,
               documentsLinksConfig: action.payload.documentsLinksConfig
           }
         }
         default: {
           return state;
         }
      }
}
  
import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRoot from './pages/AppRoot/AppRoot';
import { Provider } from 'react-redux'
import { configureStore } from './store'
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop/ScrollToTop'

import './theme.css';
import './index.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const store = configureStore();

root.render(
  <Provider store={store}>
    <BrowserRouter >
      <ScrollToTop />
      <AppRoot />
    </BrowserRouter>
  </Provider>
);

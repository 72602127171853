import { ILenConfigState } from '../types'

export const SET_LEN_CONFIG = "SET_LEN_CONFIG";
export const SET_PHONE_VISIBLE_ON_PAGE = "SET_PHONE_VISIBLE_ON_PAGE";


export interface SetLenConfigction{
    type : typeof SET_LEN_CONFIG;
    payload: ILenConfigState;
}
export interface SetPhoneVisibleOnPage{
  type : typeof SET_PHONE_VISIBLE_ON_PAGE;
  payload: boolean;
}

export type LenConfigActions =
  | SetLenConfigction
  | SetPhoneVisibleOnPage
import React, { useState } from 'react';
import OpenaLogo from '../../asserts/logos/main-logo-color.svg'
import PhoneIcon from '../../asserts/icons/phone-icon.svg'
import { AppLinks } from '../../environment/app-links'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { preparePhoneNumberToHref } from '../../common/helpers/helpers'

import './NavbarStyles.scss' 

const PhoneNumberWithIcon = () => {
   const footerConfig = useSelector((state: AppState) => state.lenConfigState.footerConfig)

   if(!footerConfig.isPhoneAvailable){
      return null
   }
   else {
      return (
         <a className='navbar-phone-wrapper' href={preparePhoneNumberToHref(footerConfig.phoneNumber)} >
            <img src={PhoneIcon} alt="phone" />
            <p>{footerConfig.phoneNumber}</p>
         </a>
      )
   }
}

const HamburgerIcon = (props: {isMenuOpen: boolean, setIsMenuOpen: (isOpen: boolean) => void}) => {
   return (
      <>
         <div id="navbar-hamburger-icon" className={props.isMenuOpen? 'open': ''} onClick={() => props.setIsMenuOpen(!props.isMenuOpen)}>
            <span /><span /><span /><span /> 
         </div>
      </>
   )
}

const PhoneHamburgerMenu = () => {

   const [isOpen, setIsOpen] = useState(false)

   return (
      <div className='mobile-navbar-hamburger-menu-wrapper'>
         <HamburgerIcon setIsMenuOpen={setIsOpen} isMenuOpen={isOpen}/>
         {
            isOpen && (
               <nav className='mobile-navbar-inner-menu-wrapper'>
                  <Link onClick={() => setIsOpen(false)} to={AppLinks.howItWorks} className='how-it-works mobile-menu-nav-links-item'>Jak to działa?</Link>
                  <Link onClick={() => setIsOpen(false)} to={AppLinks.faq} className='faq mobile-menu-nav-links-item'>Pytania i odpowiedzi</Link>
                  <Link onClick={() => setIsOpen(false)} to={AppLinks.contact} className='contact mobile-menu-nav-links-item'>Kontakt</Link>
                  <Link  onClick={() => setIsOpen(false)} to={AppLinks.default} style={{opacity: '0'}}></Link> {/** PLACEHOLDER - Bez linku do strony głównej menu nie działa poprawnie */}
               </nav>
            )
         }
      </div>
   )
}

const Navbar = () => {
   return (
      <>
         <nav className="page-navbar">
            <hr className='separator navbar-separator'/>
               <div className='page-navbar-outer-wrapper'>
                  <div className='page-navbar-inner-wrapper'>
                     <a href={AppLinks.home} className='page-logo'>
                        <img src={OpenaLogo} alt={"Opena logo"}/>
                     </a>
                     <div className='navbar-links-wrapper'>
                        <Link to={AppLinks.howItWorks} className='how-it-works nav-links-item'>Jak to działa?</Link>
                        <Link to={AppLinks.faq} className='faq nav-links-item'>Pytania i odpowiedzi</Link>
                        <Link to={AppLinks.contact} className='contact nav-links-item'>Kontakt</Link>
                     </div>
                     <PhoneNumberWithIcon />
                     <PhoneHamburgerMenu />
                  </div>
               </div>
         </nav>
      </>
   )
}

export default Navbar

import { ILoanInitCalculate, ILoanInitLimits } from '../../service/LoanInitService'

export const SET_LOAN_CALCULATE_PARAMS = "SET_LOAN_CALCULATE_PARAMS"
export const TOGGLE_CALCULATOR_OPEN = "TOGGLE_CALCULATOR_OPEN"
export const SET_LOAN_LIMITS = "SET_LOAN_LIMITS"

export interface SetLoanCalculateAction {
   type: typeof SET_LOAN_CALCULATE_PARAMS;
   payload: ILoanInitCalculate;
}
export interface SetToggleCalculatorOpenAction {
   type: typeof TOGGLE_CALCULATOR_OPEN;
   payload: boolean
}
export interface SetLoanLimitsAction {
   type: typeof SET_LOAN_LIMITS;
   payload: ILoanInitLimits
}

export type LoanCalculatorActions = 
   | SetToggleCalculatorOpenAction
   | SetLoanCalculateAction
   | SetLoanLimitsAction

